// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-layout-js": () => import("/opt/build/repo/src/components/blog-layout.js" /* webpackChunkName: "component---src-components-blog-layout-js" */),
  "component---src-components-tutorial-layout-js": () => import("/opt/build/repo/src/components/tutorial-layout.js" /* webpackChunkName: "component---src-components-tutorial-layout-js" */),
  "component---src-components-serie-layout-js": () => import("/opt/build/repo/src/components/serie-layout.js" /* webpackChunkName: "component---src-components-serie-layout-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-2-js": () => import("/opt/build/repo/src/pages/contact2.js" /* webpackChunkName: "component---src-pages-contact-2-js" */),
  "component---src-pages-dreamy-garden-js": () => import("/opt/build/repo/src/pages/dreamy-garden.js" /* webpackChunkName: "component---src-pages-dreamy-garden-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-me-2-js": () => import("/opt/build/repo/src/pages/me-2.js" /* webpackChunkName: "component---src-pages-me-2-js" */),
  "component---src-pages-project-js": () => import("/opt/build/repo/src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-tutorials-js": () => import("/opt/build/repo/src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

